import tippy from 'tippy.js';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { toPng } from 'html-to-image';
import svgPanZoom from 'svg-pan-zoom';

const seatsBooking = {
	plan: document.querySelector('.seats-plan'),
	svg: document.querySelector('.seats-plan > svg'),
	svg_table: document.querySelector('.seats-plan > svg + svg'),
	seats_paths: document.querySelectorAll('g.seat'),
	price: document.querySelector('.price .woocommerce-Price-amount'),
	qty: document.querySelector('.quantity input'),
	qty_add: document.querySelector('.quantity button.arrow-plus'),
	qty_sub: document.querySelector('.quantity button.arrow-minus'),
	seats_data_wrapper: document.querySelector('[data-seats]'),
	warn_select_seats: document.querySelector('.info-please-select-seats'),
	style_loading: 'pointer-events: none; opacity: 0.5',
	no_style: '',
	savedSeats: [],
	init() {
		let adminSeats = document.querySelector('body').classList.contains('wp-admin');
		if (adminSeats) {
			this.plan = document.querySelector('.seats-plan');
			this.svg = document.querySelector('.seats-plan > svg');
			this.seats_paths = document.querySelectorAll('g.seat');
			this.price = document.querySelector('.price .woocommerce-Price-amount');
			this.qty = document.querySelector('.quantity input');
			this.qty_add = document.querySelector('.quantity button.arrow-plus');
			this.qty_sub = document.querySelector('.quantity button.arrow-minus');
			this.seats_data_wrapper = document.querySelector('[data-seats]');
			this.warn_select_seats = document.querySelector('.info-please-select-seats');
			this.style_loading = 'pointer-events: none; opacity: 0.5';
			this.no_style = '';
			this.savedSeats = [];
		}
		this.product_id = (this.plan) ? this.plan.getAttribute('data-event-plan') : null;
		if (this.plan && this.product_id) {
			if (!adminSeats) {
				this.initZoom();
			}
			// 1. Disable it first & add reservations
			this.plan.setAttribute('style', this.style_loading);
			jQuery.when(this.ajaxCheckSeats()).done((a) => {
				if (a.success) {
					this.updatePlan(a.seats, a.reserved);
					this.plan.setAttribute('style', this.no_style); // 1. Enable plan
					if (!adminSeats) {
						this.watchClicks();
						this.tooltips();
					}
				}
			});
		}
		hideTicket()
	},
	ajaxCheckSeats() {
		return jQuery.ajax({
			type: 'POST',
			url: tc_scripts.url,
			data: {
				action: 'getSeatsBookings',
				product: this.product_id
			}
		});
	},
	ajaxSaveSeat(seats, status) {
		return jQuery.ajax({
			type: 'POST',
			url: tc_scripts.url,
			data: {
				action: 'saveSeats',
				product: this.product_id,
				seats: seats,
				status: status
			}
		})
	},
	clickSeat(e, seat) {
		const booked = seat.getAttribute('data-booked');
		const pending = seat.getAttribute('data-pending');
		const reserved = seat.getAttribute('data-reserved');
		const seatId = seat.id;
		const seatIdArray = seatId.match(/[a-zA-Z]+|[0-9]+/g);
		const is_valid_id = (seatIdArray.length == 2 && !isNaN(seatIdArray[0]) && (/[a-zA-Z]/).test(seatIdArray[1])) ? true : false;
		if (!booked && !pending && is_valid_id) {
			let status = null;
			seat.setAttribute('style', this.style_loading);
			if (!reserved || reserved == 0) {
				seat.setAttribute('data-reserved', 1);
				updateTickets();
				seat.setAttribute('style', this.style_loading);
				status = 1;
				this.savedSeats.push(seatId);
			}
			else if (reserved && reserved == 1) {
				seat.setAttribute('data-reserved', 0);
				updateTickets();
				status = 0;
				let newArray = this.savedSeats.filter(item => item !== seatId);
				this.savedSeats = newArray;
			}
			(this.savedSeats.length >= 1) ? this.qty.value = this.savedSeats.length : null;
			seat.setAttribute('style', this.no_style);
			jQuery.when(this.ajaxSaveSeat(this.savedSeats, status)).done((a) => {
				this.seatDataMarkup(status, seatId);
				seat.setAttribute('style', this.no_style);
			});
		}
		hideTicket()
	},
	seatDataMarkup(status, seatId) {
		const el = this.seats_data_wrapper.querySelector('div[data-seat="' + seatId + '"]');
		if (status == 0) {
			(el) ? el.classList.add('hidden') : null;
		} else {
			if (el) {
				el.classList.remove('hidden');
			} else {
				const wrapper = document.createElement('div');
				const seat_div = document.createElement('div');
				const txtSpan = document.createElement('span');
				txtSpan.innerHTML = 'Sitzplatz: ';
				const p = this.price.cloneNode(true);
				seat_div.classList.add('highlight-seat-id');
				seat_div.innerHTML = seatId;
				wrapper.setAttribute('data-seat', seatId);
				wrapper.appendChild(txtSpan);
				wrapper.appendChild(seat_div);
				wrapper.appendChild(p);
				this.seats_data_wrapper.appendChild(wrapper);
			}
		}
	},
	updatePlan(seats, reserved) {
		this.seats_paths.forEach(seat => {
			// 0: free, 1: pending, 2: booked
			// reserved: only until seat is in cart
			if (seats[seat.id] === '1') {
				seat.setAttribute('data-pending', 1);
			}
			if (seats[seat.id] === '2') {
				seat.setAttribute('data-booked', 1);
			}
			if (reserved && reserved.includes(seat.id)) {
				seat.setAttribute('data-reserved', 1);
				updateTickets();
				this.savedSeats.push(seat.id);
			}
		});
		if (document.querySelector('body').classList.contains('wp-admin')) {
			setTimeout(() => {
				let seatPlanContainer = document.querySelector('.seats-plan-container')
				html2canvas(seatPlanContainer).then(function (canvas) {
					htmlToImage.toJpeg(canvas, { quality: 1, width: 1340, height: seatPlanContainer.dataset.name == 'sommerfest' ? 1394 : 1115, canvasWidth: 1340, canvasHeight: seatPlanContainer.dataset.name == 'sommerfest' ? 1394 : 1115 })
						.then(function (dataUrl) {
							var link = document.createElement('a')
							link.download = seatPlanContainer.parentNode.parentNode.parentNode.parentNode.parentNode.dataset.name + '.jpeg'
							link.href = dataUrl
							link.click()
							link.remove()
							link.download = seatPlanContainer.parentNode.querySelector('button').textContent = 'Sitzplätze exportieren'
							link.download = seatPlanContainer.parentNode.querySelector('button').disabled = false
							seatPlanContainer.remove()
						})
				})
			}, 500)
		}
		hideTicket()
	},
	watchClicks() {
		this.seats_paths.forEach(seat => {
			seat.addEventListener('click', (e) => {
				this.clickSeat(e, seat);
			});
			seat.addEventListener('touchstart', (e) => {
				this.clickSeat(e, seat);
			});
		});
		this.qty_add.addEventListener('click', (e) => {
			if (this.savedSeats.length == 0 || this.savedSeats.length != this.qty.value) {
				this.warn_select_seats.classList.remove('hidden');
			}
		});
		this.qty_sub.addEventListener('click', (e) => {
			if (this.savedSeats.length == 0 || this.savedSeats.length != this.qty.value) {
				this.warn_select_seats.classList.remove('hidden');
			}
		});
	},
	tooltips() {
		const price = this.price ? this.price.innerHTML : '';

		tippy('.seat', {
			content(reference) {
				const seatId = reference.id;
				// const seatIdArray = seatId.match(/[a-zA-Z]+|[0-9]+/g);
				// const seat_html = seatIdArray.join(' ');
				const html = seatId + price;
				return html;
			},
			allowHTML: true,
			arrow: false,
			placement: 'right',
			offset: [0, 0]
		});
	},
	initZoom() {
		window.zoomEventPlan = svgPanZoom(this.svg, {
			zoomEnabled: true,
			zoomScaleSensitivity: 0.5,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			contain: true,
			fit: true,
			center: 1
		});
		window.zoomEventPlanTable = svgPanZoom(this.svg_table, {
			zoomEnabled: true,
			zoomScaleSensitivity: 0.5,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			contain: true,
			fit: true,
			center: 1
		});
		// Custom controls
		document.getElementById('seats-plan__zoom-in').addEventListener('click', function (ev) {
			ev.preventDefault()
			window.zoomEventPlan.zoomIn()
			window.zoomEventPlanTable.zoomIn()
		});
		document.getElementById('seats-plan__zoom-out').addEventListener('click', function (ev) {
			ev.preventDefault()
			window.zoomEventPlan.zoomOut()
			window.zoomEventPlanTable.zoomOut()
		});
		document.getElementById('seats-plan__reset').addEventListener('click', function (ev) {
			ev.preventDefault()
			window.zoomEventPlan.resetZoom()
			window.zoomEventPlanTable.resetZoom()
		});
		window.addEventListener("resize", function () {
			window.zoomEventPlan.resize();
			window.zoomEventPlanTable.resize();
			window.zoomEventPlan.fit();
			window.zoomEventPlanTable.fit();
			window.zoomEventPlan.center();
			window.zoomEventPlanTable.center();
		});
	}
}

function updateTickets() {
	let seats = document.querySelectorAll('.seat[data-reserved="1"]').length
	let tickets = document.querySelectorAll('.wc-box-office-ticket-fields').length
	updateSeatsNames()
	if (seats < 2) {
		// Only one ticket
		while (tickets > 1) {
			document.querySelector('.arrow-minus').click()
			tickets = document.querySelectorAll('.wc-box-office-ticket-fields').length
			updateSeatsNames()
		}
	} else {
		while (tickets > seats) {
			// Remove ticket
			document.querySelector('.arrow-minus').click()
			tickets = document.querySelectorAll('.wc-box-office-ticket-fields').length
			seats = document.querySelectorAll('.seat[data-reserved="1"]').length
			updateSeatsNames()
		}
		if (tickets > 0) {
			while (tickets < seats) {
				// Add ticket
				document.querySelector('.arrow-plus').click()
				tickets = document.querySelectorAll('.wc-box-office-ticket-fields').length
				seats = document.querySelectorAll('.seat[data-reserved="1"]').length
				updateSeatsNames()
			}
		}
	}
	document.querySelectorAll('.wc-box-office-ticket-fields').forEach(ticket => {
		if (!ticket.classList.contains('active')) {
			ticket.classList.add('active')
		}
	})
}

function updateSeatsNames() {
	let seatNames = document.querySelectorAll('.seat[data-reserved="1"]')
	let ticketNames = document.querySelectorAll('.wc-box-office-ticket-fields')
	let inputNumber = getSeatPlanInput()
	if (seatNames.length > 0 && ticketNames.length > 0) {
		for (let index = 0; index < ticketNames.length; index++) {
			if (seatNames[index]) {
				if (inputNumber) {
					ticketNames[index].querySelectorAll('.form-row')[inputNumber].querySelector('input').value = '(' + seatNames[index].id.toString() + ')'
				}
				ticketNames[index].querySelector('a').textContent = 'Ticket #' + (index + 1) + ' (' + seatNames[index].id.toString() + ')'
			}
		}
	} else {
		if (inputNumber) {
			ticketNames[0].querySelectorAll('.form-row')[inputNumber].querySelector('input').value = ''
		}
		ticketNames[0].querySelector('a').textContent = 'Ticket #1'
	}
}

function hideTicket() {
	let seatplanFields = document.querySelectorAll('.seats-template .wc-box-office-ticket-fields-body .form-row label')
	seatplanFields.forEach(field => {
		if (field.textContent.toLowerCase().includes('sitzplatz')) {
			field.parentElement.style.display = 'none'
		}
	})
}

function getSeatPlanInput() {
	let seatplanFields = document.querySelectorAll('.seats-template .wc-box-office-ticket-fields-body .form-row label')
	for (let index = 0; index < seatplanFields.length; index++) {
		if (seatplanFields[index].textContent.toLowerCase().includes('sitzplatz')) {
			return index
		}
	}
	return undefined
}

export { seatsBooking }

